<template>
	<div class="report-custom-detail">
		<div class="back-icon" v-if="back == 0">
			<i class="el-icon-arrow-left" @click="goBack"></i>
		</div>
		<div class="top-box">
			<img @dragstart.prevent class="left-img" :src="bookUrl" alt="" />
			<div class="right-box">
				<div class="right-name">{{ detailData.name }}</div>
				<div class="item-box-right">
					<div class="item-name-right">咨询电话：</div>
					<div class="item-main-right">{{ servicePhone }}</div>
				</div>
				<div class="item-box-right">
					<div class="item-name-right">定制说明：</div>
					<div class="item-main-right">{{ detailData.notice }}</div>
				</div>
			</div>
		</div>
		<div class="item-box">
			<div class="item-name">报告简介：</div>
			<div class="item-main">{{ detailData.introduction }}</div>
		</div>
		<div class="back-blue_box">
			<el-button @click="goBackBottom" type="primary" size="small" class="back-blue">立即定制</el-button>
		</div>
		<!-- <div class="item-box">
      <div class="item-name">特别声明：</div>
      <div class="item-main">{{ detailData.statement }}</div>
    </div> -->
		<div class="file-box">
			<div class="file-name-box">
				<div class="file-img"></div>
				<div class="file-name">报告目录</div>
			</div>
			<div class="file-content" @scroll="scrollEvent">
				<!-- 报告内容...... -->
				<pdf v-for="item in pageArray" :key="item" :src="src" :page="item"></pdf>
			</div>
		</div>
	</div>
</template>

<script>
import CMapReaderFactory from "vue-pdf/src/CMapReaderFactory.js";
import pdf from "vue-pdf";
import uni from "@/utils/uni-webview.js";
import wx from "@/utils/jweixin-1.3.2.js";
import { mapGetters } from "vuex";
export default {
	components: {
		pdf,
	},
	data() {
		return {
			type: "", // 研究报告类型
			detailData: {
				name: "",
				introduction: "",
				type: "电子版刊物",
				tel: "",
				notice: "本报告为定制内容，请阅读下方目录后，点击“立即定制”或通过客服反馈您的需求。",
				statement: "该内容为本公司原创，拥有唯一著作权，未经本公司事先允许，任何组织和个人不得以任何方式进行转载、复制。",
			},
			numPages: "", // 总页数
			times: 1,
			yushu: 1,
			pageArray: [],
			currentPage: 1,
			nextShow: false,
			src: "",
			bookUrl: "",
			back: 0,
		};
	},
	computed: {
		...mapGetters(["servicePhone"]),
	},
	mounted() {
		if (this.$route.query.back == 1) {
			this.back = 1;
		} else {
			this.back = 0;
		}
		let type = this.$route.query.type;
		if (type == 1) {
			this.detailData.name = "区域市场研究报告";
			this.detailData.introduction = "综合分析目标区域市场的经济、财政、投资、政策、产业等情况，结合项目招中标大数据，对市场体量、市场分布、市场集中度开放度、客户及竞争对手进行系统深入地分析，帮助用户企业制定针对性市场开发策略。";
			this.bookUrl = require("../../assets/img/consult/reportCustom/book-one.jpg");
			this.src = "/static/pdf-file1.pdf";
		} else if (type == 2) {
			this.detailData.name = "细分市场研究报告";
			this.detailData.introduction = "综合分析建筑业细分市场的规模地位、政策导向、产业布局等，结合项目招中标情况，对细分市场的分支领域进行挖掘分析，帮助用户企业制定针对性新业务开发策略。";
			this.bookUrl = require("../../assets/img/consult/reportCustom/book-two.jpg");
			this.src = "/static/pdf-file2.pdf";
		} else if (type == 3) {
			this.detailData.name = "客户研究报告";
			this.detailData.introduction = "对目标区域或领域客户的业务范围、经营状况、战略规划、业绩要求、评标规则、工程降造、历史供应商、关联关系等情况进行综合分析，结合客户需求及竞争情况，帮助用户企业制定针对性客户开发策略。";
			this.bookUrl = require("../../assets/img/consult/reportCustom/book-three.png");
			this.src = "/static/pdf-file3.pdf";
		} else {
			this.detailData.name = "竞争研究报告";
			this.detailData.introduction = "对目标区域或领域竞争主体的业务范围、经营状况、战略规划、业绩资质、核心能力、主要客户及合作单位等情况进行综合分析，开展优劣势分析，帮助用户企业制定针对性市场竞争策略。";
			this.bookUrl = require("../../assets/img/consult/reportCustom/book-four.png");
			this.src = "/static/pdf-file4.pdf";
		}
		this.type = type;
		this.getPdfDetail();
	},
	methods: {
		goBack() {
			uni.navigateBack();
		},
		goBackBottom() {
			try {
				if (this.back == 1) {

					wx.miniProgram.postMessage({
						data: {
							action: "backbottom",
						},
					});
					wx.miniProgram.navigateBack()

				} else {
					uni.postMessage({
						data: {
							action: "backbottom",
						},
					});
					uni.navigateBack();
				}
			} catch (e) {
				console.log('err', e)
			}

		},
		scrollEvent(e) {
			if (Math.ceil(e.currentTarget.scrollTop + e.currentTarget.clientHeight) >= e.currentTarget.scrollHeight) {
				//容差：20px
				console.log("滚动到底部");
				if (this.currentPage == this.times - 1) {
					this.nextShow = false;
				}
				if (this.currentPage == this.times) {
					// this.nextShow = false;
					return;
				}
				if ((this.currentPage + 1) * 5 <= this.numPages) {
					for (let i = this.currentPage * 5 + 1; i <= (this.currentPage + 1) * 5; i++) {
						this.pageArray.push(i);
					}
				} else {
					for (let i = this.currentPage * 5 + 1; i <= this.numPages; i++) {
						this.pageArray.push(i);
					}
				}
				this.currentPage++;
			}
		},
		// 获取pdf详情
		getPdfDetail() {
			const loading = this.$loading({
				lock: true,
				text: "加载中...",
				spinner: "el-icon-loading",
				background: "rgba(255, 255, 255, 0.3)",
				fullscreen: false,
			});
			let src = pdf.createLoadingTask({
				url: this.src,
				CMapReaderFactory,
				// cMapUrl: "https://cdn.jsdelivr.net/npm/pdfjs-dist@2.5.207/cmaps/",
				// cMapPacked: true
			});
			console.log(this.src);
			// 获取pdf页数
			src.promise.then(pdf => {
				this.numPages = pdf.numPages;
				loading.close();
				this.times = Number.parseInt(this.numPages / 5);
				this.yushu = this.numPages % 5;

				if (this.yushu > 0) {
					this.times++;
					if (this.times == 1) {
						this.nextShow = false;
						for (let i = 1; i <= this.yushu; i++) {
							this.pageArray.push(i);
						}
					} else {
						this.nextShow = true;
						for (let i = 1; i <= 5; i++) {
							this.pageArray.push(i);
						}
					}
				} else if (this.yushu == 0) {
					if (this.times == 1) {
						this.nextShow = false;
					} else {
						this.nextShow = true;
					}
					for (let i = 1; i <= 5; i++) {
						this.pageArray.push(i);
					}
				}
			});
		},
		// 超出继续阅读
		next() {
			console.log(this.currentPage, this.times);
			if (this.currentPage == this.times - 1) {
				this.nextShow = false;
			}
			if (this.currentPage == this.times) {
				// this.nextShow = false;
				return;
			}
			if ((this.currentPage + 1) * 5 <= this.numPages) {
				for (let i = this.currentPage * 5 + 1; i <= (this.currentPage + 1) * 5; i++) {
					this.pageArray.push(i);
				}
			} else {
				for (let i = this.currentPage * 5 + 1; i <= this.numPages; i++) {
					this.pageArray.push(i);
				}
			}
			this.currentPage++;
		},
	},
};
</script>
<style lang="less">
@import "./index.less";
</style>
